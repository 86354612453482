import Vue from 'vue'
import VueRouter from 'vue-router'
const Inicio = () => import("@/views/Inicio.vue");
const DefaultContainer = () => import("@/components/DefaultContainer.vue");

Vue.use(VueRouter)

const routes = [{
  path: "/",
  redirect: "/inicio",
  name: "start",
  component: DefaultContainer,
  children: [{
    path: '/inicio',
    name: 'Home',
    component: Inicio
  }]
}]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: "open active",
  scrollBehavior: () => ({
    y: 0
  }),
  base: process.env.BASE_URL,
  routes
})

export default router